<template>
    <v-menu dense open-on-hover>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                block
                :disabled="disabled"
                v-bind="attrs"
                v-on="on"
                :class="cButtonClass"
                :height="buttonHeight"
                :title="toggleName">
                <!-- <v-icon small :class="toggleVal == null || toggleVal == 'Off' ? '' : 'success--text'">{{ icon }}</v-icon> -->
                <v-icon v-if="icon != null" left>{{ icon }}</v-icon>{{ toggleName || 'Off' }}<v-icon right>mdi-menu-down</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item-group
                v-model="toggleVal"
                @change="update">
                <template v-for="(f, i) in dOptions">
                    <v-list-item :key="i" :value="f.value">
                        <template v-slot:default="{ active }">
                            <v-list-item-action>
                                <v-icon v-if="active" small>mdi-check</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ f.name }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'BT-Select-Automation',
    data: function() {
        return {
            guideOptions: [
                { name: 'Global', value: 'Settings' },
                { name: 'Individual', value: 'Agreements' }
            ],
            largeOptions: [
                { name: 'Off', value: 'Off' },
                { name: 'Daily', value: 'Daily' },
                { name: 'Weekly', value: 'Weekly' },
                { name: 'Monthly', value: 'Monthly' },
                { name: 'Yearly', value: 'Yearly' }
            ],
            smallOptions: [
                { name: 'Off', value: 'Off' },
                { name: 'Hourly', value: 'Hourly' },
                { name: 'Every 3 Hours', value: 'EveryThreeHours' },
                { name: 'Every 12 Hours', value: 'EveryTwelveHours' },
                { name: 'Daily', value: 'Daily' },
                { name: 'Weekly', value: 'Weekly' }
            ],
            toggleVal: null,
            triggerOptions: [
                { name: 'Manual', value: 'Manual' },
                { name: 'Auto', value: 'Auto' }
            ]
        }
    },
    props: {
        buttonClass: {
            type: String,
            default: null
        },
        buttonHeight: {
            type: String,
            default: null
        },
        dailyOnly: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: String,
            default: 'Off'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'mdi-clock'
        },
        isLargeRange: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: null
        },
        useGuideOptions: {
            type: Boolean,
            default: false
        },
        useTriggerOptions: {
            type: Boolean,
            default: false
        },
        value: {
            Type: String,
            default: null
        },
    },
    computed: {
        cButtonClass() {
            var e = this.buttonClass || '';

            if (!this.useGuideOptions && !this.useTriggerOptions && !this.isLengthyArray(this.options)) {
                e = `${e} ${this.toggleVal == null || this.toggleVal == 'Off' ? 'error--text' : 'success--text'}`;
            }

            return e;
        },
        dOptions() {
            if (this.options != null) {
                return this.options;
            }

            if (this.useGuideOptions) {
                return this.guideOptions;
            }

            if (this.useTriggerOptions) {
                return this.triggerOptions;
            }

            if (this.dailyOnly) {
                return [{ name: 'Off', value: 'Off' }, { name: 'Daily', value: 'Daily' }];
            }

            return this.isLargeRange ? this.largeOptions : this.smallOptions;
        },
        toggleName() {
            var res = this.dOptions.find(x => x.value == this.toggleVal);
            return res ? res.name : null;
        }
    },
    mounted() {
        if (!this.value) {
            this.toggleVal = this.defaultValue; //this.options[0];
        }
        else {
            this.toggleVal = this.value; //this.options.find(y => y.value == this.value);
        }
    },
    watch: {
        value: function(val) {
            if (!val) {
                this.toggleVal = 'Off';
            }
            else {
                this.toggleVal = val; //this.options.find(y => y.value == val);
            }
        }
    },
    methods: {
        update(val) {
            this.$emit('input', val);
            this.$emit('change', val);
        }
    }
}
</script>